import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from './icons'

class Footer extends Component {

  state = {
    colActive: [],
  }

  _toggleCol = (itemId) => {
    let current = this.state.colActive[itemId]
    let { colActive } = this.state
    colActive[itemId] = !current
    this.setState({ colActive })
  }

  render() {

    let { colActive } = this.state

    return (
      <footer className='footer'>
        <div className='footer__top'>
          <div className='footer__inner'>
            <div className='footer__cols'>
              <div className='footer__col'>
                <div className='footer__logo'>
                  <Link to='/' title='Kollosche'>
                    <Logo color={`#FFFFFF`} />
                  </Link>
                  <p><Link className='phone' to='tel:1300576000'>1300 576 000</Link></p>
                </div>
                <div className='footer__address'>
                  <p>Kollosche Head Office<br />
                  Level 2, Oracle West<br />
                  19 Elizabeth Avenue<br />
                  Broadbeach QLD 4218</p>
                </div>
              </div>
              <div className='footer__col'>
                <h4 className={colActive[0] ? 'active' : ''} onClick={() => this._toggleCol(0)}>Buy</h4>
                <ul className={colActive[0] ? 'active' : ''}>
                  <li><Link to='https://kollosche.com.au/buy/'>Residential</Link></li>
                  <li><Link to='https://kollosche.com.au/buy-commercial/'>Commercial</Link></li>
                  <li><Link to='https://kollosche.com.au/projects/'>New Projects</Link></li>
                  <li><Link to='https://kollosche.com.au/off-market/'>Off Market</Link></li>
                  <li><Link to='https://kollosche.com.au/suburb-guides/'>Suburb Profiles</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <h4 className={colActive[1] ? 'active' : ''} onClick={() => this._toggleCol(1)}>Sold</h4>
                <ul className={colActive[1] ? 'active' : ''}>
                  <li><Link to='https://kollosche.com.au/sold/'>Residential</Link></li>
                  <li><Link to='https://kollosche.com.au/sold-commercial/'>Commercial</Link></li>
                  <li><Link to='https://kollosche.com.au/projects/'>New Projects</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <h4 className={colActive[2] ? 'active' : ''} onClick={() => this._toggleCol(2)}>Lease</h4>
                <ul className={colActive[2] ? 'active' : ''}>
                  <li><Link to='https://kollosche.com.au/lease/'>Residential</Link></li>
                  <li><Link to='https://kollosche.com.au/lease-commercial/'>Commercial</Link></li>
                  <li><Link to='https://id.propertyme.com/auth/'>Tenant Portal</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <h4 className={colActive[3] ? 'active' : ''} onClick={() => this._toggleCol(3)}>About</h4>
                <ul className={colActive[3] ? 'active' : ''}>
                  <li><Link to='https://kollosche.com.au/about-us/'>About Us</Link></li>
                  <li><Link to='https://kollosche.com.au/our-team/'>Team</Link></li>
                  <li><Link to='https://kollosche.com.au/news/'>News</Link></li>
                  <li><Link to='https://kollosche.com.au/contact-us/'>Contact Us</Link></li>
                  <li><Link to='https://kollosche.com.au/careers/'>Careers</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <h4 className={colActive[4] ? 'active' : ''} onClick={() => this._toggleCol(4)}>Connect</h4>
                <ul className={colActive[4] ? 'active' : ''}>
                  
                  <li><Link to='https://www.linkedin.com/company/kollosche/'>LinkedIn</Link></li>
                  <li><Link to='https://www.facebook.com/KolloscheGC/'>Facebook</Link></li>
                  <li><Link to='https://www.instagram.com/kollosche_/?hl=en'>Instagram</Link></li>
                  <li><Link to='https://weixin.qq.com/r/mzsCGrXEGehmrfF5926J'>WeChat</Link></li>
                  <li><Link to='https://www.youtube.com/channel/UCyVdEOWfroQCK9ScWZuCODA'>YouTube</Link></li>
                  <li><Link to='https://www.tiktok.com/@kollosche_'>TikTok</Link></li>
                </ul>
              </div>
              <div className='footer__col footer__col--mobile'>
                <ul className='footer__terms'>
                  <li><Link to='https://kollosche.com.au/terms-conditions/'>Terms &amp; Conditions</Link></li>
                  <li><Link to='https://kollosche.com.au/privacy-policy/'>Privacy Policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='footer__bottom'>
          <div className='footer__inner'>
            <div className='footer__cols'>
              <div className='footer__col'>
                <ul>
                  <li><Link to='https://kollosche.com.au/terms-conditions/'>Terms &amp; Conditions</Link></li>
                  <li><Link to='https://kollosche.com.au/privacy-policy/'>Privacy Policy</Link></li>
                </ul>
              </div>
              <div className='footer__col'>&nbsp;</div>
              <div className='footer__col'>&nbsp;</div>
              <div className='footer__col'>
                <p>© Kollosche { new Date().getFullYear() }</p>
              </div>
              <div className='footer__col'>
                <p>Design by <Link to='https://atollon.com.au'>Atollon</Link></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
