// Function to read value of a cookie
export const readCookie = (name) => {
  let cookiename = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0, l = ca.length; i < l; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ')
          c = c.substring(1, c.length);
      if (c.indexOf(cookiename) === 0)
          return c.substring(cookiename.length, c.length);
  }
  return null;
}

export const setCookie = (name, value) => {
  document.cookie = name + "=" + value + "; "
}

export const setCookieWithExpiry = (name, value, minutes) => {
  let now = new Date();
  now.setTime(now.getTime() + minutes * 60 * 1000);
  document.cookie = name + "=" + value + ";expires=" + now.toUTCString() + ";";
}

export const parseCookieToObject = (cookieString) => {
  try {
    return JSON.parse(cookieString);
  } catch (error) {
    console.error("Error parsing cookie:", error);
    return {};
  }
};