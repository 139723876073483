import React from 'react'
import PropTypes from 'prop-types'
import { Script } from 'gatsby'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import SubscribePopup from '../components/subscribe-popup'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} />
      <main>{ children }</main>
      <Footer data={data} />
      <SubscribePopup />
      <Script src="https://assets.adoberesources.net/loader.js?orgId=A8DE283C6419ADC80A495C4B%40AdobeOrg&instanceId=kolloschepropertymanagement&env=prod&geo=aus5" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
