import { setCookieWithExpiry, readCookie } from './cookie';

const getUTMFromQueryString = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Extract UTM parameters
  const utmParameters = {};
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParameters[key] = value;
    }
  });

  return utmParameters;
};

const saveUTMToCookie = (utmParameters) => {
  document.cookie = 'utmParameters=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  setCookieWithExpiry('utmParameters', JSON.stringify(utmParameters), 30);
};

const getUTMFromCookie = () => {
  const utmCookie = readCookie('utmParameters');
  return utmCookie ? JSON.parse(utmCookie) : null;
};

const handleUTMOnPageLoad = () => {
  const utmFromQueryString = getUTMFromQueryString();

  if (Object.keys(utmFromQueryString).length > 0) {
    // UTM parameters exist in the query string, save to cookie
    saveUTMToCookie(utmFromQueryString);
  }
};

export default handleUTMOnPageLoad;