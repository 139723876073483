import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo, Search, YouTube, Instagram, WeChat, Facebook, LinkedIn } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasSubNav: false,
    offCanvasSubNavItem: [],
    scrolled: false,
    divisions: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleOffCanvasSubNav = (key) => {

    let { offCanvasSubNav, offCanvasSubNavItem } = this.state
    if ( offCanvasSubNavItem[key] ) {
      offCanvasSubNav = false
      offCanvasSubNavItem[key] = false
    } else {
      offCanvasSubNav = true 
      for ( let i = 0; i < offCanvasSubNavItem.length; i++ ) {
        offCanvasSubNavItem[i] = false
      }
      offCanvasSubNavItem[key] = true
    }
    this.setState({ offCanvasSubNavItem, offCanvasSubNav })
  }

  _toggleDivisions = () => {
    this.setState({ divisions: !this.state.divisions })
  }

  render() {

    let { offCanvas, scrolled, offCanvasSubNav, offCanvasSubNavItem, divisions } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Kollosche' className='header__logo' {...props}>
              <Logo color={`#FFFFFF`} />
            </Link>
            { !process.env.GATSBY_PROJECTS_SITE &&
              <nav className='header__nav'>
                <ul>
                  <li>
                    <Link to='/buy/' {...props}>Buy</Link>
                    <ul className='header__sub-nav'>
                      <li><Link to='/buy/' {...props}>Residential</Link></li>
                      <li><Link to='/buy-commercial/' {...props}>Commercial</Link></li>
                      <li><Link to='/projects/' {...props}>New Projects</Link></li>
                      <li><Link to='/off-market/' {...props}>Off Market</Link></li>
                      <li><Link to='/suburb-guides/' {...props}>Suburb Profiles</Link></li>
                      <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
                      <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                      <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/lease/' {...props}>Lease</Link>
                    <ul className='header__sub-nav menu-lease'>
                      <li><Link to='/lease/' {...props}>Residential</Link></li>
                      <li><Link to='/lease-commercial/' {...props}>Commercial</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/sold/' {...props}>Sold</Link>
                    <ul className='header__sub-nav menu-sold'>
                      <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
                      <li><Link to='/sold/' {...props}>Residential</Link></li>
                      <li><Link to='/sold-commercial/' {...props}>Commercial</Link></li>
                      <li><Link to='/suburb-guides/' {...props}>Suburb Profiles</Link></li>
                      
                    </ul>
                  </li>
                  <li>
                    <Link to='/about-us/' {...props}>About</Link>
                    <ul className='header__sub-nav menu-about'>
                      <li className='header__divisions'>
                        <button className={divisions ? ' active' : ''} onClick={this._toggleDivisions}>Divisions</button>
                        <ul className={`header__sub-sub${divisions ? ' active' : ''}`}>
                          <li><Link to='/residential/' {...props}>Residential</Link></li>
                          <li><Link to='/commercial/' {...props}>Commercial</Link></li>
                          <li><Link to='/new-projects/' {...props}>New Projects</Link></li>
                          <li><Link to='/property-management/' {...props}>Property Management</Link></li>
                        </ul>
                      </li>
                      <li><Link to='/our-team/' {...props}>Our Team</Link></li>
                      <li><Link to='/careers/' {...props}>Careers</Link></li>
                      <li><Link to='/community/' {...props}>Community</Link></li>
                      <li><Link to='/news/' {...props}>News</Link></li>
                      <li><Link to='/contact-us/' {...props}>Contact Us</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link 
                      className='header__search-icon'
                      to='/buy/' {...props}
                    >
                      <Search color={'#FFFFFF'} />
                    </Link>
                  </li>
                  <li>
                  <button 
                    onClick={this._toggleOffCanvas} 
                    className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' } 
                    type='button'
                  >
                    <span className='lines'></span>
                  </button>
                  </li>
                </ul>
              </nav>
            }
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[0] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(0)}
                  >
                    Buy
                  </button>
                </li>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[1] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(1)}
                  >
                    Lease
                  </button>
                </li>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[2] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(2)}
                  >
                    Sold
                  </button>
                </li>
                <li><Link to='/our-team/' {...props}>Team</Link></li>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[3] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(3)}
                  >
                    About
                  </button>
                </li>
                <li><Link to='/contact-us/' {...props}>Contact</Link></li>
              </ul>
              <div className='off-canvas__social'>
                <p><Link className='phone' to='tel:1300576000'>1300 576 000</Link></p>
                <ul>
                  <li><Link to='https://www.linkedin.com/company/kollosche/'><LinkedIn color='#FFFFFF' /></Link></li>
                  <li><Link to='https://www.facebook.com/KolloscheGC/'><Facebook color='#FFFFFF' /></Link></li>
                  <li><Link to='https://www.instagram.com/kollosche_/'><Instagram color='#FFFFFF' /></Link></li>
                  <li><Link to='https://weixin.qq.com/r/mzsCGrXEGehmrfF5926J'><WeChat color='#FFFFFF' /></Link></li>
                  <li><Link to='https://www.youtube.com/channel/UCyVdEOWfroQCK9ScWZuCODA'><YouTube color='#FFFFFF' /></Link></li>
                </ul>
              </div>
            </nav>
            <div className={`off-canvas__sub-nav ${offCanvasSubNav ? 'active' : ''}`}>
              { offCanvasSubNavItem[0] &&
                <ul>
                  <li className='heading'>Buy</li>
                  <li><Link to='/buy/' {...props}>Residential</Link></li>
                  <li><Link to='/buy-commercial/' {...props}>Commercial</Link></li>
                  <li><Link to='/projects/' {...props}>New Projects</Link></li>
                  <li><Link to='/off-market/' {...props}>Off Market</Link></li>
                  <li><Link to='/suburb-guides/' {...props}>Suburb Profiles</Link></li>
                  <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
                  <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                  <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                  <li className='back'>
                    <button 
                      type='button'
                      onClick={() => this._toggleOffCanvasSubNav(0)}
                    >
                      Back to Main
                    </button>
                  </li>
                </ul>
              }
              { offCanvasSubNavItem[1] &&
                <ul>
                  <li className='heading'>Lease</li>
                  <li><Link to='/lease/' {...props}>Residential</Link></li>
                  <li><Link to='/lease-commercial/' {...props}>Commercial</Link></li>
                  <li className='back'>
                    <button 
                      type='button'
                      onClick={() => this._toggleOffCanvasSubNav(1)}
                    >
                      Back to Main
                    </button>
                  </li>
                </ul>
              }
              { offCanvasSubNavItem[2] &&
                <ul>
                  <li className='heading'>Sold</li>
                  <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
                  <li><Link to='/sold/' {...props}>Residential</Link></li>
                  <li><Link to='/sold-commercial/' {...props}>Commercial</Link></li>
                  <li><Link to='/suburb-guides/' {...props}>Suburb Profiles</Link></li>
                  <li className='back'>
                    <button 
                      type='button'
                      onClick={() => this._toggleOffCanvasSubNav(2)}
                    >
                      Back to Main
                    </button>
                  </li>
                </ul>
              }
              { offCanvasSubNavItem[3] &&
                <ul>
                  <li className='heading'>About</li>
                  <li><Link to='/about-us/' {...props}>About Us</Link></li>
                  <li className='off-canvas__divisions'>
                    <button className={`divisions-button ${divisions ? ' active' : ''}`} onClick={this._toggleDivisions}>Divisions</button>
                    <ul className={divisions ? ' active' : ''}>
                      <li><Link to='/residential/' {...props}>Residential</Link></li>
                      <li><Link to='/commercial/' {...props}>Commercial</Link></li>
                      <li><Link to='/new-projects/' {...props}>New Projects</Link></li>
                      <li><Link to='/property-management/' {...props}>Property Management</Link></li>
                    </ul>
                  </li>
                  <li><Link to='/our-team/' {...props}>Our Team</Link></li>
                  <li><Link to='/careers/' {...props}>Careers</Link></li>
                  <li><Link to='/community/' {...props}>Community</Link></li>
                  <li><Link to='/news/' {...props}>News</Link></li>
                  <li><Link to='/contact-us/' {...props}>Contact Us</Link></li>
                  <li className='back'>
                    <button 
                      type='button'
                      onClick={() => this._toggleOffCanvasSubNav(3)}
                    >
                      Back to Main
                    </button>
                  </li>
                </ul>
              }
            </div>
            <button 
              className={`header__off-canvas-close ${offCanvas && 'active'}`}
              type='button'
              onClick={() => this.setState({ offCanvas: !this.state.offCanvas })}
            />
          </div>
        </div>
      </>
    )
  }
}

export default Header
