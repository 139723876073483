import { readCookie, setCookie } from "./cookie";

const subscribePopupCookieName = '_subscribe_popup'
const subscribedCookieName = '_subscribed'

export const getSubscribePopupCookie = () => {
    const value = readCookie(subscribePopupCookieName);
    return value;
}

export const setSubscribePopupCookie = () => {
    setCookie(subscribePopupCookieName, '1')
}

export const getSubscribedCookie = () => {
    const value = readCookie(subscribedCookieName);
    return value;
}

export const setSubscribedCookie = () => {
    setCookie(subscribedCookieName, '1')
}
