import { readCookie } from "./cookie";

export const getMarketoCookie = () => {
    // Call readCookie function to get value of user's Marketo cookie
    let value = readCookie('_mkto_trk');

    if (value?.split('&').length > 1) {
        return value.split('&')[1]
    }

    return value;
}
