import React, { useEffect, useRef, useState } from "react";
import { Close, Triangle } from "./icons";
import { getSubscribePopupCookie, setSubscribePopupCookie, getSubscribedCookie } from "../utils/subscribe-popup";
import SubscribeForm from "./subscribe-form";
import { useLocation } from "@reach/router";

const SubscribePopup = () => {
  const location = useLocation();
  const [subscribePopup, setSubscribePopup] = useState(false);
  const [subscribeCookie, setSubscribeCookie] = useState(false);
  const [radioError, setRadioError] = useState(false);
  const [pathFound, setPathFound] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const subscribePopupCookie = getSubscribePopupCookie();
    const subscribedCookie = getSubscribedCookie();

    const fiveSecondDelay = [
      '/'
    ];

    const tenSecondDelay = [
      '/buy/',
      '/buy-commercial/',
      '/projects/',
      '/sold-commercial/',
      '/lease/',
      '/lease-commercial/',
      '/upcoming-auctions/',
      '/upcoming-inspections/',
      '/suburb-guides/'
    ];

    const noDelay = [
      '/about-us/',
      '/community/',
      '/privacy-policy/',
      '/terms-conditions/',
      '/careers/',
      '/news/',
      '/media-center/',
    ];

    const { pathname } = location;

    let timeoutDuration = 0;
    let pathFound = false;

    if (noDelay.includes(pathname)) {
      timeoutDuration = 0;
      pathFound = true;
    } else if (fiveSecondDelay.includes(pathname)) {
      timeoutDuration = 8000;
      pathFound = true;
    } else if (tenSecondDelay.includes(pathname)) {
      timeoutDuration = 10000;
      pathFound = true;
    }

    if (subscribedCookie) pathFound = false;
    setPathFound(pathFound);
    if (subscribePopupCookie === '1') setSubscribeCookie(true)

    if (subscribePopupCookie !== '1' && pathFound) {
      timeoutRef.current = setTimeout(() => {
        setSubscribePopup(true);
        setSubscribePopupCookie();
        setSubscribeCookie(true)
      }, timeoutDuration);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [location]);

  return (
    <>
      <div className={`popup popup--subscribe${subscribePopup ? " active" : ""}`}>
        <div className="testimonial-popup__overlay" />
        <div className="testimonial-popup contact-popup">
          <button
            className="contact-popup__close"
            onClick={() => setSubscribePopup(false)}
          >
            <Close color="#FFFFFF" />
          </button>
          <div className="contact-popup__form">
            <h4>Stay informed with the latest property listings, insights and results.</h4>
            <SubscribeForm disclaimer={true} setSubscribePopup={setSubscribePopup} setPathFound={setPathFound} />
          </div>
        </div>
      </div>
      { pathFound && !subscribePopup && subscribeCookie &&
        <button className='popup__button' onClick={() => setSubscribePopup(!subscribePopup)}>
          <span>Subscribe</span><Triangle color='#233356' />
        </button>
      }
    </>
  );
};

export default SubscribePopup;